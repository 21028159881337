import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useEffect } from "react";

const LoadingPage = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return null;
};

export default LoadingPage;
