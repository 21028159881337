import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cn from "./resources/cn.json";
import en from "./resources/en.json";
import { LOCAl_STOGARE } from "@constants/variables";
import { I18NLanguages } from "@interface/i18n.interface";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  en,
  cn,
};

export const defaultNS = "translation";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem(LOCAl_STOGARE.LANGUAGE) ?? I18NLanguages.CN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
