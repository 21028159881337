import { PORTALS } from "@constants/variables";
import {
  ApplicationAction,
  ApplicationContextProps,
  ApplicationState,
} from "@interface/application.interface";
import { Modal } from "antd";
import { createContext, useContext, useMemo, useReducer } from "react";

const applicationInitState = {
  portal: PORTALS.ROOT,
  version: "",
};

const applicationReducer = (
  state: ApplicationState,
  _action: ApplicationAction
): ApplicationState => {
  return state;
};

export const ApplicationContext = createContext<ApplicationContextProps>({
  state: applicationInitState,
  dispatch: () => {},
});

const ApplicationProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    applicationReducer,
    applicationInitState
  );

  const value = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state]);

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

// Set default props for components of libraries
Modal.defaultProps = {
  centered: true,
  mousePosition: {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  },
  transitionName: "",
};

export default ApplicationProvider;

export const useApplication = () => useContext(ApplicationContext);
