import LoadingPage from "@components/atoms/LoadingPage";
import { PAGES } from "@constants/variables";
import { Suspense, memo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/AuthContext";

import styles from "./PublicLayout.module.scss";

const PublicLayout = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const isLoaded = state.isLoaded;
  const isAuth = state.isAuth;
  const location = useLocation();

  useEffect(() => {
    if (
      isLoaded &&
      !location.pathname.includes(PAGES.RESET_PASSWORD) &&
      !location.pathname.includes(PAGES.FORGOT_PASSWORD) &&
      !location.pathname.includes(PAGES.SIGN_UP) &&
      !location.state &&
      !isAuth
    ) {
      navigate(PAGES.SIGN_IN);
    }
    if (isLoaded && isAuth) {
      navigate(PAGES.DASHBOARD);
    }
  }, [isAuth, isLoaded, location.pathname, location.state, navigate]);
  if (!isLoaded) return null;
  return (
    <Suspense fallback={<LoadingPage />}>
      <div className={styles.container}>
        <div className={styles.header}></div>
        <div className={styles.body}>{children}</div>
      </div>
    </Suspense>
  );
};

export default memo(PublicLayout);
