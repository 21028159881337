import "./App.css";
import ApplicationProvider from "./contexts/ApplicationContext";
import AuthProvider from "./contexts/AuthContext";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@lib/react-query/queryClient";
import { ConfigProvider } from "antd";
import vi_VN from "antd/locale/vi_VN";
import "dayjs/locale/vi";
import "./dayjsConfig";
import { ThemeConfig } from "antd/lib";
import BreadcrumbProvider from "./contexts/BreadcrumbContext";
import AppWithRouter from "@lib/react-router-dom/AppWithRouter";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#0074F6",
    controlHeight: 32,
    borderRadius: 8,
    colorText: "#262626",
  },
  components: {
    Tooltip: {
      colorBgSpotlight: "#262626",
      colorTextLightSolid: "#FFFFFF",
    },
    DatePicker: {
      controlHeight: 32,
      fontFamily: "Plus Jakarta Sans",
      borderRadius: 12,
    },
    Form: {
      itemMarginBottom: 16,
      labelColor: "#606266",
      labelFontSize: 14,
      fontWeightStrong: 400,
    },
    Input: {
      controlHeight: 32,
      fontFamily: "Plus Jakarta Sans",
      borderRadius: 12,
    },
    InputNumber: {
      controlHeight: 32,
      fontFamily: "Plus Jakarta Sans",
      borderRadius: 12,
    },
    Checkbox: {
      controlHeight: 20,
    },
    Notification: {
      colorTextDescription: "rgba(0, 0, 0, 0.85)",
      fontSizeHeading2: 14,
      fontSizeHeading1: 14,
      fontSizeHeading3: 14,
      fontSizeHeading4: 14,
      fontSize: 14,
      fontSizeHeading5: 14,
    },
    Table: {
      headerBorderRadius: 0,
      headerBg: "#F5F7FA",
      headerSortActiveBg: "#F5F7FA",
      headerSortHoverBg: "#F5F7FA",
      borderColor: "#EBEEF5",
      bodySortBg: "white",
    },
    Modal: {
      titleColor: "#262626",
    },
    Select: {
      borderRadius: 12,
    },
    Tag: {
      borderRadiusSM: 12,
    },
    Breadcrumb: {
      itemColor: "#606266",
      linkColor: "#606266",
      separatorColor: "#606266",
    },
  },
};

function App() {
  return (
    <ConfigProvider
      locale={vi_VN}
      theme={theme}
      autoInsertSpaceInButton={false}
    >
      <QueryClientProvider client={queryClient}>
        <ApplicationProvider>
          <AuthProvider>
            <BreadcrumbProvider>
              <AppWithRouter />
            </BreadcrumbProvider>
          </AuthProvider>
        </ApplicationProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
