import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { nonAuthRouters, routers } from "./routes";
import { lazy } from "react";
import NotFound from "@pages/not-found";
import { useAuth } from "src/contexts/AuthContext";
import PublicLayout from "@components/templates/layouts/PublicLayout";
import ProtectedLayout from "@components/templates/layouts/ProtectedLayout";
import Forbidden from "@pages/forbiden";
import { isEmpty } from "lodash";
import useAuthorization from "@hooks/useAuthor";
import { hasValue } from "src/utils/general";

const Components: any = {};

routers.concat(nonAuthRouters).forEach((router) => {
  Components[router.component] = lazy(
    () => import(`@pages/${router.component}`)
  );
});

const AppWithRouter = () => {
  const { state } = useAuth();
  const { roles, permissions } = useAuthorization();
  if (!state.isLoaded) return <div />;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <>
          {nonAuthRouters.map((router) => {
            const Component = Components[router.component];
            return (
              <Route
                key={router.key}
                element={
                  <PublicLayout>
                    <Component />
                  </PublicLayout>
                }
                path={router.path}
              />
            );
          })}
          {routers.map((router) => {
            const isBlocked = hasValue(roles, router.excludeRoles ?? []);
            const isNoneBlocked = isEmpty(router.includeRoles)
              ? true
              : hasValue(roles, router.includeRoles ?? []);
            const isNonBlockedByPermission = hasValue(
              permissions,
              router.includePermissions ?? []
            );
            if ((isBlocked || !isNoneBlocked) && !isNonBlockedByPermission) {
              return (
                <Route
                  key={router.key}
                  element={
                    <ProtectedLayout>
                      <Forbidden />
                    </ProtectedLayout>
                  }
                  path={`${router.path}`}
                />
              );
            }
            const Component = Components[router.component];
            return (
              <Route
                key={router.key}
                element={
                  <ProtectedLayout>
                    <Component />
                  </ProtectedLayout>
                }
                path={`${router.path}`}
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppWithRouter;
