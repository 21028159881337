import { RightOutlined } from "@ant-design/icons";
import { PAGES } from "@constants/variables";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Breadcrumb {
  title: string;
  href?: string;
  menu?: {
    items: Breadcrumb[];
  };
}

interface BreadcrumbContextProps {
  breadcrumb: Breadcrumb[];
  setBreadcrumb: (data: Breadcrumb[]) => void;
}

export const BreadcrumbContext = createContext<BreadcrumbContextProps>({
  breadcrumb: [],
  setBreadcrumb: (_data: Breadcrumb[]) => {},
});

const BreadcrumbProvider = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([]);
  const [isTruncate, setIsTruncate] = useState(false);

  const value = useMemo(() => {
    let breadcrumbList = [...breadcrumb];
    if (isTruncate) {
      breadcrumbList = [
        breadcrumbList[0],
        {
          title: "...",
          menu: {
            items: breadcrumbList
              .slice(1, breadcrumbList.length - 1)
              .map((i) => {
                if (i.href) {
                  return {
                    ...i,
                    key: i.title,
                    label: <Link to={i.href}>{i.title}</Link>,
                    icon: <RightOutlined />,
                    className:
                      "flex flex-row-reverse items-center gap-2 justify-between",
                  };
                }
                return { ...i, key: i.title };
              }),
          },
        },
        breadcrumbList[breadcrumbList.length - 1],
      ];
    }
    return {
      breadcrumb: [
        { title: t("menu.home"), href: PAGES.DASHBOARD },
        ...breadcrumbList,
      ],
      setBreadcrumb: setBreadcrumb,
    };
  }, [breadcrumb, isTruncate, t]);

  const handleResize = () => {
    if (window.innerWidth < 1024 && breadcrumb.length >= 3) {
      if (!isTruncate) {
        setIsTruncate(true);
      }
    } else if (isTruncate) {
      setIsTruncate(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumb, isTruncate]);

  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;

export const useBreadcrumb = () => useContext(BreadcrumbContext);
