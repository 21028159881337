import { MenuDataItem, ProLayout } from "@ant-design/pro-components";
import LoadingPage from "@components/atoms/LoadingPage";
import { PAGES, featuresHidden } from "@constants/variables";
import ExtraMenuFooterSidebar from "@components/molecules/ExtraMenuFooterSidebar";
import Logo from "@assets/image/logo.svg";
import clsx from "clsx";
import {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "src/contexts/AuthContext";
import styles from "./ProtectedLayout.module.scss";
import InvoiceListIcon from "@components/atoms/icons/InvoiceListIcon";
import AppointmentIcon from "@components/atoms/icons/AppointmentIcon";
import CustomerIcon from "@components/atoms/icons/CustomerIcon";
import PrintIconMenu from "@components/atoms/icons/PrintIconMenu";
import ServiceIcon from "@components/atoms/icons/ServiceIcon";
import DiamondIcon from "@components/atoms/icons/DiamondIcon";

const ProtectedLayout = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const isLoaded = state.isLoaded;
  const isAuth = state.isAuth;
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (isLoaded && !isAuth) {
      navigate(PAGES.SIGN_IN, {
        replace: true,
      });
    }
  }, [isAuth, isLoaded, navigate]);

  const menuItemRender = useCallback(
    (options: MenuDataItem, element: React.ReactNode) => {
      return <Link to={options.path ?? "/"}>{element}</Link>;
    },
    []
  );

  const ROUTES = useMemo(
    () => ({
      path: "/",
      routes: [
        {
          path: PAGES.INVOICE,
          name: "Thu ngân",
          hideInMenu: featuresHidden.includes(PAGES.INVOICE),
          icon: <PrintIconMenu />,
        },
        {
          path: PAGES.ORDERS,
          name: "Hoá đơn",
          hideInMenu: featuresHidden.includes(PAGES.ORDERS),
          icon: <InvoiceListIcon />,
        },
        {
          path: PAGES.APPOINTMENT,
          name: "Lịch hẹn",
          hideInMenu: featuresHidden.includes(PAGES.APPOINTMENT),
          icon: <AppointmentIcon />,
        },
        {
          path: PAGES.PRODUCT,
          name: "Sản phẩm",
          hideInMenu: featuresHidden.includes(PAGES.PRODUCT),
          icon: <DiamondIcon />,
        },
        {
          path: PAGES.CUSTOMERS,
          name: "Khách hàng",
          hideInMenu: featuresHidden.includes(PAGES.CUSTOMERS),
          icon: <CustomerIcon />,
        },
        {
          path: PAGES.SERVICE,
          name: "Dịch vụ",
          hideInMenu: featuresHidden.includes(PAGES.SERVICE),
          icon: <ServiceIcon />,
        },
      ],
    }),
    []
  );

  const handleCollapsed = useCallback(() => setToggle((prev) => !prev), []);

  const customButtonCollapsed = useCallback(() => null, []);

  const extraMenuFooter = useCallback(() => {
    return (
      <ExtraMenuFooterSidebar
        handleCollapsed={handleCollapsed}
        isToggle={toggle}
      />
    );
  }, [handleCollapsed, toggle]);

  const menuHeaderRender = useCallback(() => {
    return (
      <Link to={PAGES.HOME} className={`${styles.mainLogo} w-full`}>
        <div className="w-full h-[70x] flex justify-center items-center">
          <img
            className="w-full h-[70px]"
            src={toggle ? Logo : Logo}
            alt="Logo"
          />
        </div>
      </Link>
    );
  }, [toggle]);

  if (!isLoaded) return null;
  return (
    <ProLayout
      id="protected-layout"
      style={{ minHeight: "100vh" }}
      route={ROUTES}
      menuItemRender={menuItemRender}
      fixSiderbar
      fixedHeader
      layout="side"
      className={clsx(
        styles.proLayoutCustomContainer,
        toggle ? styles.isCollapseSideBar : styles.isNotCollapseSideBar
      )}
      collapsedButtonRender={customButtonCollapsed}
      collapsed={toggle}
      onCollapse={handleCollapsed}
      headerTitleRender={extraMenuFooter}
      menuHeaderRender={menuHeaderRender}
    >
      <Suspense fallback={<LoadingPage />}>{children}</Suspense>
    </ProLayout>
  );
};

export default memo(ProtectedLayout);
