import styles from "./ExtraMenuFooterSidebar.module.scss";
import CustomButton from "@components/atoms/custom-component/CustomButton";
interface IExtraMenuFooterSidebar {
  handleCollapsed: () => void;
  isToggle: boolean;
}

const ExtraMenuFooterSidebar: React.FC<IExtraMenuFooterSidebar> = ({
  handleCollapsed,
}) => {
  return (
    <div className={styles.container}>
      <CustomButton
        className={styles.customCollapsedButton}
        onClick={handleCollapsed}
        typeButton="icon"
      />
    </div>
  );
};

export default ExtraMenuFooterSidebar;
