import { me } from "@api/authenticaion";
import {
  AuthAction,
  AuthContextProps,
  AuthState,
} from "@interface/auth.interface";
import { User } from "@interface/user.interface";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import { AxiosError } from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useApplication } from "./ApplicationContext";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@constants/variables";
import { queryClient } from "@lib/react-query/queryClient";
import { IResponseSuccessParams } from "@interface/axios.interface";
const authInitState: AuthState = {
  isAuth: false,
  isLoaded: false,
  isLoading: false,
  user: {} as User,
};

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "SetUser":
      return {
        ...state,
        user: action.user,
        isLoading: false,
        isAuth: true,
        isLoaded: true,
      };
    case "SetLoadAuthFail":
      return {
        ...state,
        isLoaded: true,
        isAuth: false,
        isLoading: false,
      };
    case "Logout":
      return {
        ...state,
        user: {} as User,
        isLoading: false,
        isAuth: false,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<AuthContextProps>({
  state: authInitState,
  dispatch: () => {},
  fetchUser: () => ({}) as any,
  logout: () => {},
});

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(AuthReducer, authInitState);
  const { data, refetch, isError } = useQuery<
    IResponseSuccessParams<User>,
    AxiosError
  >({
    queryKey: ["me"],
    queryFn: me,
    enabled: false,
    gcTime: 0,
    retry: false,
    staleTime: Infinity,
  });

  const { dispatch: applicationDispatch } = useApplication();

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      refetch();
    } else {
      dispatch({ type: "SetLoadAuthFail" });
    }
  }, [refetch]);

  useEffect(() => {
    if (isError) {
      dispatch({ type: "SetLoadAuthFail" });
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: "SetUser",
        user: data.data,
      });
    }
  }, [data, applicationDispatch]);

  const fetchUser = useCallback(() => {
    return refetch();
  }, [refetch]);

  const logout = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    queryClient.removeQueries({
      queryKey: ["me"],
    });
    queryClient.removeQueries({
      queryKey: ["sign-in"],
    });
    dispatch({
      type: "Logout",
    });
  }, []);

  const value = useMemo(() => {
    return {
      state,
      dispatch,
      fetchUser,
      logout,
    };
  }, [fetchUser, logout, state]);

  return (
    <AuthContext.Provider value={value}>
      {state.isLoading || !state.isLoaded ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spin />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
