const PrintIconMenu = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1330_3503)">
        <path
          d="M19 6.89413V4.89413C19 3.83327 18.5786 2.81585 17.8284 2.06571C17.0783 1.31556 16.0609 0.894135 15 0.894135L9 0.894135C7.93913 0.894135 6.92172 1.31556 6.17157 2.06571C5.42143 2.81585 5 3.83327 5 4.89413V6.89413C3.67441 6.89572 2.40356 7.42302 1.46622 8.36035C0.528882 9.29769 0.00158786 10.5685 0 11.8941L0 16.8941C0.00158786 18.2197 0.528882 19.4906 1.46622 20.4279C2.40356 21.3653 3.67441 21.8925 5 21.8941C5 22.6898 5.31607 23.4528 5.87868 24.0155C6.44129 24.5781 7.20435 24.8941 8 24.8941H16C16.7956 24.8941 17.5587 24.5781 18.1213 24.0155C18.6839 23.4528 19 22.6898 19 21.8941C20.3256 21.8925 21.5964 21.3653 22.5338 20.4279C23.4711 19.4906 23.9984 18.2197 24 16.8941V11.8941C23.9984 10.5685 23.4711 9.29769 22.5338 8.36035C21.5964 7.42302 20.3256 6.89572 19 6.89413ZM7 4.89413C7 4.3637 7.21071 3.85499 7.58579 3.47992C7.96086 3.10485 8.46957 2.89413 9 2.89413H15C15.5304 2.89413 16.0391 3.10485 16.4142 3.47992C16.7893 3.85499 17 4.3637 17 4.89413V6.89413H7V4.89413ZM17 21.8941C17 22.1594 16.8946 22.4137 16.7071 22.6012C16.5196 22.7888 16.2652 22.8941 16 22.8941H8C7.73478 22.8941 7.48043 22.7888 7.29289 22.6012C7.10536 22.4137 7 22.1594 7 21.8941V17.8941C7 17.6289 7.10536 17.3746 7.29289 17.187C7.48043 16.9995 7.73478 16.8941 8 16.8941H16C16.2652 16.8941 16.5196 16.9995 16.7071 17.187C16.8946 17.3746 17 17.6289 17 17.8941V21.8941ZM22 16.8941C22 17.6898 21.6839 18.4528 21.1213 19.0155C20.5587 19.5781 19.7956 19.8941 19 19.8941V17.8941C19 17.0985 18.6839 16.3354 18.1213 15.7728C17.5587 15.2102 16.7956 14.8941 16 14.8941H8C7.20435 14.8941 6.44129 15.2102 5.87868 15.7728C5.31607 16.3354 5 17.0985 5 17.8941V19.8941C4.20435 19.8941 3.44129 19.5781 2.87868 19.0155C2.31607 18.4528 2 17.6898 2 16.8941V11.8941C2 11.0985 2.31607 10.3354 2.87868 9.77281C3.44129 9.21021 4.20435 8.89413 5 8.89413H19C19.7956 8.89413 20.5587 9.21021 21.1213 9.77281C21.6839 10.3354 22 11.0985 22 11.8941V16.8941Z"
          fill="white"
        />
        <path
          d="M18 10.8944H16C15.7348 10.8944 15.4804 10.9998 15.2929 11.1873C15.1054 11.3749 15 11.6292 15 11.8944C15 12.1597 15.1054 12.414 15.2929 12.6015C15.4804 12.7891 15.7348 12.8944 16 12.8944H18C18.2652 12.8944 18.5196 12.7891 18.7071 12.6015C18.8946 12.414 19 12.1597 19 11.8944C19 11.6292 18.8946 11.3749 18.7071 11.1873C18.5196 10.9998 18.2652 10.8944 18 10.8944Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1330_3503">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.894135)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PrintIconMenu;
