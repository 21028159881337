import { RoleEnum } from "@constants/authorization";
import { PermissionEnum } from "@interface/auth.interface";
import { flatten } from "lodash";
import { useContext, useMemo } from "react";
import { AuthContext } from "src/contexts/AuthContext";
import { hasValue } from "src/utils/general";

const useAuthorization = (
  rolesPermit?: RoleEnum[],
  permissionsPermit?: PermissionEnum[]
) => {
  const context = useContext(AuthContext);
  const roles: RoleEnum[] = useMemo(() => {
    return context.state?.user?.roles?.map((d) => d.code) ?? [];
  }, [context.state?.user?.roles]);
  const permissions: PermissionEnum[] = useMemo(() => {
    const permissionsObject = flatten(
      context.state?.user?.roles?.map((d) => d.permissions) ?? []
    ).filter((d) => d);
    return permissionsObject.map((d) => d.code);
  }, [context.state?.user?.roles]);

  const isRolePermit = hasValue(roles, rolesPermit ?? []);
  const isPermissionPermit = hasValue(permissions, permissionsPermit ?? []);
  const isRoleOrPermissionPermit = isRolePermit || isPermissionPermit;

  return {
    roles,
    permissions,
    isRolePermit,
    isPermissionPermit,
    isRoleOrPermissionPermit,
  };
};

export default useAuthorization;
