const CustomerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_430_298)">
        <path
          d="M12 10C11.2044 10 10.4413 9.68393 9.87868 9.12132C9.31607 8.55871 9 7.79565 9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7C15 7.79565 14.6839 8.55871 14.1213 9.12132C13.5587 9.68393 12.7956 10 12 10ZM8.968 15.249C9.17364 14.6065 9.57807 14.046 10.1229 13.6483C10.6678 13.2506 11.3249 13.0363 11.9995 13.0363C12.6741 13.0363 13.3312 13.2506 13.8761 13.6483C14.4209 14.046 14.8254 14.6065 15.031 15.249C15.0973 15.506 15.263 15.7261 15.4916 15.861C15.7202 15.9958 15.993 16.0343 16.25 15.968C16.507 15.9017 16.7271 15.736 16.862 15.5074C16.9968 15.2788 17.0353 15.006 16.969 14.749C15.658 9.802 8.339 9.804 7.032 14.749C6.9959 14.8772 6.98576 15.0113 7.00217 15.1434C7.01859 15.2755 7.06123 15.4031 7.12759 15.5185C7.19396 15.634 7.28271 15.735 7.38865 15.8157C7.49458 15.8963 7.61556 15.9551 7.74448 15.9884C7.87341 16.0217 8.00769 16.0289 8.13944 16.0096C8.2712 15.9903 8.39777 15.9449 8.51174 15.876C8.6257 15.8071 8.72477 15.7162 8.80312 15.6085C8.88147 15.5009 8.93752 15.3786 8.968 15.249ZM12.009 23.665C11.5156 23.6653 11.0394 23.4841 10.671 23.156L6.923 20H4C2.93913 20 1.92172 19.5786 1.17157 18.8284C0.421427 18.0783 0 17.0609 0 16V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0L20 0C21.0609 0 22.0783 0.421427 22.8284 1.17157C23.5786 1.92172 24 2.93913 24 4V16C24 17.0609 23.5786 18.0783 22.8284 18.8284C22.0783 19.5786 21.0609 20 20 20H17.147L13.3 23.18C12.9436 23.4943 12.4842 23.6669 12.009 23.665ZM4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H7.289C7.52465 18 7.75273 18.0832 7.933 18.235L11.979 21.645L16.151 18.229C16.3303 18.0809 16.5555 18 16.788 18H20C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2H4Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_430_298">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CustomerIcon;
