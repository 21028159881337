import { PAGES } from "@constants/variables";
import { RouteInfo } from "@interface/router.interface";

export const nonAuthRouters: RouteInfo[] = [
  {
    key: PAGES.SIGN_IN,
    path: PAGES.SIGN_IN,
    component: "sign-in",
  },
  {
    key: PAGES.SIGN_UP,
    path: PAGES.SIGN_UP,
    component: "register",
  },
  {
    key: PAGES.FORGOT_PASSWORD,
    path: PAGES.FORGOT_PASSWORD,
    component: "forgot-password",
  },
  {
    key: PAGES.RESET_PASSWORD,
    path: PAGES.RESET_PASSWORD,
    component: "reset-password",
  },
];

export const routers: RouteInfo[] = [
  {
    key: PAGES.HOME,
    path: PAGES.HOME,
    component: "home",
  },
  {
    key: PAGES.INVOICE,
    path: PAGES.INVOICE,
    component: "invoice",
  },
  {
    key: PAGES.DASHBOARD,
    path: PAGES.DASHBOARD,
    component: "dashboard",
  },
  {
    key: PAGES.ORDERS,
    path: PAGES.ORDERS,
    component: "orders-list",
  },
  {
    key: PAGES.ORDERS_DETAILS,
    path: PAGES.ORDERS_DETAILS,
    component: "order-details",
  },
  {
    key: PAGES.APPOINTMENT,
    path: PAGES.APPOINTMENT,
    component: "appointment",
  },
  {
    key: PAGES.PRODUCT,
    path: PAGES.PRODUCT,
    component: "products",
  },
  {
    key: PAGES.SERVICE,
    path: PAGES.SERVICE,
    component: "service",
  },
  {
    key: PAGES.CUSTOMERS,
    path: PAGES.CUSTOMERS,
    component: "customers",
  },
  {
    key: PAGES.CUSTOMERS_DETAILS,
    path: PAGES.CUSTOMERS_DETAILS,
    component: "customer-details",
  },
];
