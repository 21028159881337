const DiamondIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1240_4092)">
        <path
          d="M23.1469 10.365L22.3599 9.29404C21.8145 8.55197 21.1026 7.94824 20.2815 7.5314C19.4603 7.11457 18.5528 6.89626 17.6319 6.89404H6.38288C5.4809 6.8964 4.5915 7.10581 3.78323 7.50613C2.97495 7.90645 2.26938 8.487 1.72087 9.20304L0.899875 10.277C0.233357 11.1563 -0.0800167 12.2532 0.0213957 13.3519C0.122808 14.4506 0.631666 15.4716 1.44787 16.214L9.43788 23.921C10.1396 24.5469 11.0476 24.8919 11.9879 24.89C12.9481 24.8882 13.8739 24.5321 14.5879 23.89L22.5239 16.257C23.3448 15.5302 23.8639 14.5224 23.9792 13.432C24.0945 12.3416 23.7976 11.2475 23.1469 10.365ZM20.7469 10.472L21.5339 11.546C21.6129 11.6559 21.6825 11.7724 21.7419 11.894H16.9509C16.8785 11.4152 16.7537 10.9457 16.5789 10.494L15.9389 8.89404H17.6319C18.2383 8.89519 18.836 9.03855 19.377 9.3126C19.918 9.58666 20.3872 9.98377 20.7469 10.472ZM11.9899 20.982L9.22088 14.712C9.12072 14.4468 9.04507 14.173 8.99488 13.894H14.9469C14.9049 14.1139 14.8461 14.3302 14.7709 14.541L11.9899 20.982ZM9.09988 11.894C9.1417 11.7148 9.19412 11.5381 9.25688 11.365L10.2409 8.89404H13.7849L14.7219 11.237C14.8041 11.451 14.871 11.6705 14.9219 11.894H9.09988ZM3.31088 10.415C3.67244 9.94339 4.13741 9.561 4.67 9.2973C5.20259 9.03361 5.78858 8.89565 6.38288 8.89404H8.08288L7.39988 10.623C7.23509 11.0333 7.11213 11.4591 7.03288 11.894H2.24487C2.31346 11.7527 2.39622 11.6187 2.49188 11.494L3.31088 10.415ZM2.81088 14.748C2.55 14.5114 2.34505 14.2197 2.21088 13.894H6.98288C7.04919 14.4328 7.1791 14.9618 7.36988 15.47L10.1969 21.87L2.81088 14.748ZM13.7709 21.908L16.6249 15.294C16.7905 14.8414 16.9059 14.3719 16.9689 13.894H21.7999C21.6583 14.2373 21.4421 14.5448 21.1669 14.794L13.7709 21.908Z"
          fill="white"
        />
        <path
          d="M11.9999 4.89404C12.2652 4.89404 12.5195 4.78869 12.7071 4.60115C12.8946 4.41361 13 4.15926 13 3.89404V1.89404C13 1.62883 12.8946 1.37447 12.7071 1.18694C12.5195 0.9994 12.2652 0.894043 11.9999 0.894043C11.7347 0.894043 11.4804 0.9994 11.2928 1.18694C11.1053 1.37447 10.9999 1.62883 10.9999 1.89404V3.89404C10.9999 4.15926 11.1053 4.41361 11.2928 4.60115C11.4804 4.78869 11.7347 4.89404 11.9999 4.89404Z"
          fill="white"
        />
        <path
          d="M16.5529 4.79397C16.6709 4.85333 16.7996 4.88871 16.9314 4.89805C17.0632 4.90739 17.1956 4.89051 17.3208 4.84838C17.4461 4.80626 17.5618 4.73973 17.6611 4.65263C17.7605 4.56554 17.8417 4.45961 17.8999 4.34097L18.8999 2.34097C19.0184 2.1036 19.0378 1.82886 18.9538 1.57719C18.8698 1.32551 18.6892 1.11752 18.4519 0.998972C18.2145 0.880421 17.9398 0.861019 17.6881 0.945035C17.4364 1.02905 17.2284 1.2096 17.1099 1.44697L16.1099 3.44697C16.0501 3.56444 16.0142 3.69254 16.0042 3.82393C15.9941 3.95532 16.0102 4.08739 16.0513 4.21257C16.0925 4.33774 16.158 4.45355 16.2441 4.55332C16.3301 4.6531 16.4351 4.73488 16.5529 4.79397Z"
          fill="white"
        />
        <path
          d="M6.105 4.34097C6.22355 4.579 6.4318 4.76019 6.68394 4.84468C6.93609 4.92916 7.21146 4.91002 7.4495 4.79147C7.68753 4.67292 7.86872 4.46467 7.9532 4.21252C8.03769 3.96038 8.01855 3.685 7.9 3.44697L6.9 1.44697C6.84129 1.32944 6.76002 1.22462 6.66081 1.1385C6.5616 1.05237 6.4464 0.986636 6.32178 0.945035C6.07011 0.861019 5.79536 0.880421 5.558 0.998972C5.32063 1.11752 5.14007 1.32551 5.05606 1.57719C4.97204 1.82886 4.99144 2.1036 5.11 2.34097L6.105 4.34097Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1240_4092">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.894043)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DiamondIcon;
