import { LoginResponse } from "@interface/auth.interface";
import { User } from "@interface/user.interface";
import { LoginForm } from "@pages/sign-in";
import { RegisterFormType } from "@pages/register";
import { AxiosInstance } from "@lib/axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@constants/variables";
import { IResponseSuccessParams } from "@interface/axios.interface";
import { PasswordResetData } from "@components/templates/reset-password/ResetPasswordView";

export const login = (
  input: LoginForm
): Promise<IResponseSuccessParams<LoginResponse>> =>
  AxiosInstance.post("/authenticate", {
    username: input.username,
    password: input.password,
    remember: input.remember,
  }).then((response) => {
    localStorage.setItem(ACCESS_TOKEN, response.data.id_token);
    localStorage.setItem(REFRESH_TOKEN, response.data.id_token);
    return response.data;
  });

export const me = (): Promise<IResponseSuccessParams<User>> =>
  AxiosInstance.get("/account").then((response) => response.data);

export const register = (
  input: RegisterFormType
): Promise<IResponseSuccessParams> =>
  AxiosInstance.post("/register", {
    lastName: input.lastName,
    firstName: input.firstName,
    email: input.email,
    password: input.password,
    login: input.login,
    activated: input.activated,
  }).then((response) => {
    return response.data;
  });

export const forgotPassword = (
  email: string
): Promise<IResponseSuccessParams> =>
  AxiosInstance.post("/account/reset-password/init", {
    email,
  }).then((response) => {
    return response.data;
  });

export const resetPass = (
  input: PasswordResetData
): Promise<IResponseSuccessParams> =>
  AxiosInstance.post("/account/reset-password/finish", {
    key: input.key,
    newPassword: input.newPassword,
  }).then((response) => {
    return response.data;
  });
