import PhoneCodeJson from "@services/json/phone-code.json";
export const trimWord = (word: string) => word.trim();

export function emulatePromise(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("Promise resolved after 1 second");
    }, time);
  });
}

export const replaceParams = (path: string, params: any) => {
  let newPath = path;
  Object.keys(params).forEach((key) => {
    const regex = new RegExp(`\\:${key}`);
    newPath = newPath.replace(regex, params[key]);
  });
  return newPath;
};

export const sleep = (ms: number = 100) =>
  new Promise<void>((rel) => setTimeout(() => rel(), ms));

export function filterRecursive(array: any, fn: any) {
  return array.reduce((r: any, o: any) => {
    const children = filterRecursive(o.children || [], fn);
    if (fn(o) || children.length)
      r.push(Object.assign({}, o, children.length && { children }));
    return r;
  }, []);
}

export const getParentKey = (key: React.Key, tree: any): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

export const phoneCodeOption = PhoneCodeJson.map((item) => ({
  value: "+" + item.code,
  label: "+" + item.code,
  key: item.iso,
}));

export const hasValue = (inputValue: any[], source: any[]) => {
  return inputValue.some((i) => source.includes(i));
};

export function findChildById(tree: any, id: any) {
  if (tree.id === id) {
    return tree;
  }

  if (tree.children && tree.children.length > 0) {
    for (let i = 0; i < tree.children.length; i++) {
      const found: any = findChildById(tree.children[i], id);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

export function downloadFile(url: string, name: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    });
}

export const getFileExt = (code: string) => {
  switch (code) {
    case "jpeg":
      return "jpg";
    default:
      return code;
  }
};

// @ts-ignore
export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(",");
  // @ts-ignore
  const mime = arr[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const flatten: any = (array: any[], parentPath?: string) =>
  array.flatMap(({ name, path, routes }) => [
    { parent: parentPath, name, path },
    ...flatten(routes || [], path),
  ]);

export const getArrRoute = (arr: any[], path: string, arrRes: any[]) => {
  let curPath = arr.find((x) => x.path === path);
  let exact = true;
  if (!curPath) {
    curPath = arr
      .sort((a: any, b: any) => b?.path?.length - a?.path?.length)
      .find((x) => path.startsWith(`${x.path}/`));
    exact = false;
  }
  if (curPath?.parent) {
    getArrRoute(arr, curPath?.parent, arrRes).res.push(curPath);
  } else {
    arrRes.push(curPath);
  }
  return { res: arrRes, exact };
};

export const excelDownload = (blob: BlobPart, fileName?: string) => {
  const url = URL.createObjectURL(
    new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  if (fileName) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};
