import { KeysOfPages } from "@interface/router.interface";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export const PAGES = {
  HOME: "/",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  SIGN_IN: "/auth/login",
  SIGN_UP: "/auth/register",
  VIEW_PROFILE: "/profile/my-profile",
  SETTING_PROFILE: "/profile/settings",

  DASHBOARD: "/dashboard",
  INVOICE: "/invoice",
  ORDERS: "/orders-list",
  ORDERS_DETAILS: "/orders/details/:id",
  APPOINTMENT: "/appointment",
  PRODUCT: "/product",
  CUSTOMERS: "/customers",
  CUSTOMERS_DETAILS: "/customer/details/:id",
  SERVICE: "/service",
} as const;

export const PORTALS = {
  ADMIN: "admin",
  CREW: "",
  ROOT: "",
};

export const LOCAl_STOGARE = {
  LANGUAGE: "lang",
  IS_EDITED_FORM: "is_edited_form",
};
export const FORMAT_DATE = {
  standard: "DD/MM/YYYY",
  standard_date_fns: "yyyy-MM-dd",
  standard_date: "YYYY-MM-DD",
  standard_picker: "YYYY-MM-DD",
  standard_date_format: "yyyy/MM/dd",
  standard_date_placeholder: "yyyy-mm-dd",
  standard_date_format_ISO: "YYYY-MM-DDTHH:mm:ss[Z]",
  standard_date_basic: "YYYYMMDD",
  standard_date_time: "yyyy-MM-dd H:mm",
  standard_time: "HH:mm",
  standard_year_month: "YYYY-MM",
};
export const SORT_MAPPING: Mapping = {
  ascend: "ASC",
  descend: "DESC",
};
// Type
type Mapping = {
  [key: string]: string;
};
export const MAX_DOC_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const DEFAULT_DEBOUNCE_TIME = 600;
export const MAX_OF_EMAIL = 50;
export const MAX_OF_NAME = 50;
export const MAX_OF_TELEPHONE = 20;
export const MAX_OF_REMARK = 255;
export const DEFAULT_LOCALES = "en-US";
export const CHINA_LOCALES = "zh-CN";
export const MAX_DIGITS = 2;
export const MIN_DIGITS = 0;
export const FILE_STALE_TIME = 36000;
export const PER_PAGE = 10;
export const featuresHidden = (
  (process.env.REACT_APP_FEATURE_HIDDEN?.split(",") ?? []) as KeysOfPages[]
)
  .map((d) => PAGES[d])
  .filter((d) => d);

export const DEFAULT_PAGINATION = {
  page: 1,
  size: 20,
};
