import { useCallback } from "react";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { PAGES } from "@constants/variables";
import CustomButton from "@components/atoms/custom-component/CustomButton";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const navigateToHome = useCallback(() => {
    navigate(PAGES.HOME);
  }, [navigate]);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <CustomButton
          htmlType="button"
          typeButton="primary"
          onClick={navigateToHome}
        >
          Back Home
        </CustomButton>
      }
    />
  );
};

export default NotFound;
