import { useCallback } from "react";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { PAGES } from "@constants/variables";
import { useTranslation } from "react-i18next";
import CustomButton from "@components/atoms/custom-component/CustomButton";

const Forbidden: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToHome = useCallback(() => {
    navigate(PAGES.HOME);
  }, [navigate]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Result
      status="403"
      title="403"
      subTitle={t("common.error.403")}
      extra={
        <div className="flex gap-2 justify-center">
          <CustomButton htmlType="button" onClick={navigateBack}>
            Back
          </CustomButton>
          <CustomButton
            htmlType="button"
            typeButton="primary"
            onClick={navigateToHome}
          >
            Home
          </CustomButton>
        </div>
      }
    />
  );
};

export default Forbidden;
