/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */

import { IResponseErrorParams } from "@interface/axios.interface";
import { notification } from "antd";
import axios, { AxiosError } from "axios";
import {
  ACCESS_TOKEN,
  LOCAl_STOGARE,
  PAGES,
  REFRESH_TOKEN,
} from "@constants/variables";
import { I18NLanguages } from "@interface/i18n.interface";

const isServer = () => {
  return typeof window === "undefined";
};

let accessToken = "";
const baseURL = process.env.REACT_APP_BACKEND_URL!;

export const setAccessToken = (_accessToken: string) => {
  accessToken = _accessToken;
};

export const getAccessToken = () => accessToken;

export const AxiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Expose-Headers": "Content-Disposition,X-Suggested-Filename",
  },
  withCredentials: true, // to send cookie
});

AxiosInstance.interceptors.request.use((config: any) => {
  const newAccessToken = localStorage.getItem(ACCESS_TOKEN);
  const lang = localStorage.getItem(LOCAl_STOGARE.LANGUAGE);
  if (lang) {
    config.headers["Accept-Language"] = lang === I18NLanguages.CN ? "zh" : "en";
  }
  if (newAccessToken && config?.url !== PAGES.SIGN_IN) {
    config.headers.Authorization = `Bearer ${newAccessToken}`;
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: AxiosError<IResponseErrorParams>) => {
    // check conditions to refresh token

    const errorResponse = error.response?.data;
    if (
      errorResponse?.status === 403 &&
      errorResponse?.message === "PERMISSION_DENIED"
    ) {
      notification.error({
        message: "Permission denied.",
        key: "permission_denied",
      });
      window.location.href = PAGES.VIEW_PROFILE;
      return Promise.reject(error);
    }
    if (
      error.response?.status === 401 &&
      error?.response?.data?.error === "Unauthorized" &&
      !isServer()
    ) {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      refreshToken(error);
      return (window.location.href = PAGES.SIGN_IN);
    }
    return Promise.reject(error);
  }
);

let fetchingToken = false;
let subscribers: ((token: string) => any)[] = [];

const onAccessTokenFetched = (token: string) => {
  subscribers.forEach((callback) => callback(token));
  subscribers = [];
};

const addSubscriber = (callback: (token: string) => any) => {
  subscribers.push(callback);
};

const refreshToken = async (oError: AxiosError) => {
  try {
    const { response }: any = oError;
    console.log(fetchingToken, "fetchingToken");
    // create new Promise to retry original request
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((token: string) => {
        response!.config.headers["Authorization"] = `Bearer ${token}`;
        resolve(axios(response!.config));
      });
    });

    // check whether refreshing token or not
    if (!fetchingToken) {
      fetchingToken = true;
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      // refresh token
      const { data } = await AxiosInstance.post("/auth/refresh-token", {
        refreshToken: refreshToken,
      });
      // check if this is server or not. We don't wanna save response token on server.
      if (!isServer()) {
        setAccessToken(data.data.accessToken);
        localStorage.setItem(REFRESH_TOKEN, data.data.refreshToken);
        localStorage.setItem(ACCESS_TOKEN, data.data.accessToken);
      }
      // when new token arrives, retry old requests
      onAccessTokenFetched(data.data.accessToken);
    }
    return retryOriginalRequest;
  } catch (error) {
    window.location.href = PAGES.SIGN_IN;
    return Promise.reject(oError);
  } finally {
    fetchingToken = false;
  }
};
